<template>
    <div class="al-content">      
        <div class="content-top">
            <div class="content-top clearfix">
                <h1 class="al-title ">Product</h1>
            </div>
        </div>

        <div class="row ">

            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-body">
                        <div>


                            <div class="font-weight-bold">Last Update: <span style="color:burlywood">{{lastUpload}}</span></div>
                            <div class="font-weight-bold">By: <span style="color:burlywood">{{lastUploadBy}}</span></div>
                            <div class="font-weight-bold">Filename: <span style="color:burlywood">{{lastFile}}</span></div>


                        </div>
                        <div class="mt-3">
                            <table class="table table-hover table-bordered" id="datatable">
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th id="columnName">Name</th>
                                        <th>Supplier</th>
                                        <th>Region</th>
                                        <th>Color</th>
                                        <th>RegularSold</th>
                                        <th>PeakSold</th>
                                        <th>Regular Months To clear</th>
                                        <th>Peak Months To clear</th>
                                        <th>Inventory <br /> (Tồn Kho)</th>
                                        <th>Low Warning</th>
                                        <th>High Warning</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in data" :key="item.Code" :style="{'background-color':item.Remain<=0?'#ff000021 !important':'inherit'}">
                                        <td @click="ViewHistory(item.Code,item.Name)">
                                            <a href="javascript:void(0);"
                                               class="editable-click"
                                               data-bs-toggle="tooltip" data-bs-placement="top"
                                               title="View History">
                                                {{item.Code}}
                                            </a>
                                        </td>
                                        <td style="width:50%">{{item.Name}}</td>

                                        <td>{{item.Catalog}}</td>
                                        <td>{{item.SubCatalog1}}</td>
                                        <td>{{item.SubCatalog2}}</td>
                                        <td>
                                            <div class="input-group-sm" v-show="RegularSoldEdit[item.Code]">
                                                <input type="text" class="form-control" aria-label="" :value="item.RegularSold" :id="`regularSold_`+item.Code">
                                                <input type="text" class="form-control" aria-label="" placeholder="Note" :id="`regularNote_`+item.Code">
                                                <button type="submit" class="btn btn-primary btn-sm" title="Submit" aria-label="Submit" @click="savePeakEdit('Regular',item.Code)">
                                                    <i class="ion-checkmark-round"></i>
                                                </button>
                                                <button class="btn btn-default  btn-sm" type="button" id="button-addon2" @click="togglePeakEdit('Regular',item.Code)"><i class="ion-close-round"></i></button>
                                            </div>

                                            <a v-show="!RegularSoldEdit[item.Code]&&checkFullAcl('Product/EditPeak')" href="javascript:void(0);"
                                               class="editable-click"
                                               data-bs-toggle="tooltip"
                                               data-bs-placement="top"
                                               title="Edit RegularSold"
                                               @click="togglePeakEdit('Regular',item.Code)">
                                                {{formatNumber(item.RegularSold)}}
                                            </a>

                                            <span v-if="!checkFullAcl('Product/EditPeak')">
                                                {{formatNumber(item.RegularSold)}}
                                            </span>
                                        </td>
                                        <td>
                                            <div class="input-group-sm" v-show="PeakSoldEdit[item.Code]">
                                                <input type="text" class="form-control" aria-label="" :value="item.PeakSold" :id="`peakSold_`+item.Code">
                                                <input type="text" class="form-control" aria-label="" placeholder="Note" :id="`peakNote_`+item.Code">
                                                <button type="submit" class="btn btn-primary btn-sm" title="Submit" aria-label="Submit" @click="savePeakEdit('Peak',item.Code)">
                                                    <i class="ion-checkmark-round"></i>
                                                </button>
                                                <button class="btn btn-default  btn-sm" type="button" id="button-addon2" @click="togglePeakEdit('Peak',item.Code)"><i class="ion-close-round"></i></button>
                                            </div>

                                            <a v-show="!PeakSoldEdit[item.Code]&&checkFullAcl('Product/EditPeak')" href="javascript:void(0);"
                                               class="editable-click"
                                               data-bs-toggle="tooltip"
                                               data-bs-placement="top"
                                               title="Edit RegularSold"
                                               @click="togglePeakEdit('Peak',item.Code)">
                                                {{formatNumber(item.PeakSold)}}
                                            </a>
                                            <span v-if="!checkFullAcl('Product/EditPeak')">
                                                {{formatNumber(item.PeakSold)}}
                                            </span>
                                        </td>
                                        <td>{{Math.round(item.RegularSold>0?item.Remain/item.RegularSold:0)}}</td>
                                        <td>{{Math.round(item.PeakSold>0?item.Remain/item.PeakSold:0)}}</td>
                                        <td>{{formatNumber(item.Remain)}}</td>
                                        <td>
                                            <div class="input-group-sm" v-show="LowLimitEdit[item.Code]">
                                                <input type="text" class="form-control" aria-label="" :value="item.LowLimit" :id="`LowLimit_`+item.Code">
                                                <button type="submit" class="btn btn-primary btn-sm" title="Submit" aria-label="Submit" @click="savePeakEdit('LowLimit',item.Code)">
                                                    <i class="ion-checkmark-round"></i>
                                                </button>
                                                <button class="btn btn-default  btn-sm" type="button" id="button-addon2" @click="togglePeakEdit('LowLimit',item.Code)"><i class="ion-close-round"></i></button>
                                            </div>

                                            <a v-show="!LowLimitEdit[item.Code]&&checkFullAcl('Product/EditPeak')" href="javascript:void(0);"
                                               class="editable-click"
                                               data-bs-toggle="tooltip"
                                               data-bs-placement="top"
                                               title="Edit"
                                               @click="togglePeakEdit('LowLimit',item.Code)">
                                                {{formatNumber(item.LowLimit)}}
                                            </a>
                                            <span v-if="!checkFullAcl('Product/EditPeak')">
                                                {{formatNumber(item.LowLimit)}}
                                            </span>
                                        </td>
                                        <td>
                                            <div class="input-group-sm" v-show="HighLimitEdit[item.Code]">
                                                <input type="text" class="form-control" aria-label="" :value="item.HighLimit" :id="`HighLimit_`+item.Code">
                                                <button type="submit" class="btn btn-primary btn-sm" title="Submit" aria-label="Submit" @click="savePeakEdit('HighLimit',item.Code)">
                                                    <i class="ion-checkmark-round"></i>
                                                </button>
                                                <button class="btn btn-default  btn-sm" type="button" id="button-addon2" @click="togglePeakEdit('HighLimit',item.Code)"><i class="ion-close-round"></i></button>
                                            </div>

                                            <a v-show="!HighLimitEdit[item.Code]&&checkFullAcl('Product/EditPeak')" href="javascript:void(0);"
                                               class="editable-click"
                                               data-bs-toggle="tooltip"
                                               data-bs-placement="top"
                                               title="Edit"
                                               @click="togglePeakEdit('HighLimit',item.Code)">
                                                {{formatNumber(item.HighLimit)}}
                                            </a>
                                            <span v-if="!checkFullAcl('Product/EditPeak')">
                                                {{formatNumber(item.HighLimit)}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>




    </div>
</template>
<style>
    .dataTables_wrapper .dataTables_paginate .paginate_button {
        color: white !important;
    }
    .dataTables_length {
        position: absolute;
        margin-top: 50px;
    }
  
</style>
<script>
    //https://therichpost.com/vue-3-datatable-with-export-buttons-print-csv-copy-with-dynamic-data/
    //Datatable Modules
    import "datatables.net-dt/js/dataTables.dataTables"
    import "datatables.net-dt/css/jquery.dataTables.min.css"
    import "datatables.net-buttons/js/dataTables.buttons.js"
    import "datatables.net-buttons/js/buttons.colVis.js"
    import "datatables.net-buttons/js/buttons.flash.js"
    import "datatables.net-buttons/js/buttons.html5.js"
    import "datatables.net-buttons/js/buttons.print.js"
    import "jszip";
    import pdfMake from "pdfmake/build/pdfmake";
    import pdfFonts from "pdfmake/build/vfs_fonts";
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    window.JSZip = require('jszip');
    var htmlToPdfmake = require("html-to-pdfmake");

    import { Chart, registerables } from "chart.js";
    



    import $ from 'jquery';
    import 'datatables.net-responsive'

    
    


    import c from '@/mylib/common'
    import { mapState, mapActions } from 'vuex'



    export default {
        name: `Product`,
        data: function () {
            return {                
                lastUploadBy: '',
                lastFile: '',
                lastUpload:'',
                LayoutModel: {},
                data: [],                
                mapProduct: {},       
                dataTable: null,                
                RegularSoldEdit: {},
                PeakSoldEdit: {},       
                LowLimitEdit: {},
                HighLimitEdit: {},
                dataHistoryChart: [],
                ChartInstance: null,
                RequestChardCodes:[],
            }
        },       

        computed: mapState({
            isCheckedLogin: state => state.view.isCheckedLogin,
        }),
        methods: {
            ...mapActions({
                'loading': 'view/setLoading',
                'showModal': 'view/showModal',
                'showToast': 'view/showToast',
                'setGroups': 'user/setGroups',
                
                'call': 'api/call'
            }),
            checkAcl(action) {
                return this.$store.state.session.acl.includes(this.$options.name + "/" + action);
            },
            checkFullAcl(router) {
                return this.$store.state.session.acl.includes(router);
            },
            start() {
                this.getAll();
                
            },
            formatDate(dstr, notime) {
                let d = new Date(dstr);
                if (d == "Invalid Date") return "";
                return c.formatDate(d, notime);
            },
            formatNumber(n) {                
                return c.formatNumber(n);
            },
            ViewHistory(code,name) {
                if (this.checkAcl('ViewHistory'))
                    this.$store.dispatch("view/viewHistory", { code: code,name:name });
            },
            togglePeakEdit(type, Code) {
                console.log('togglePeakEdit');
                if (!this.checkFullAcl('Product/EditPeak')) return;
                console.log(type);
                if (type == "Regular") {
                    this.RegularSoldEdit[Code] = !this.RegularSoldEdit[Code];
                    if (this.RegularSoldEdit[Code]) {
                        setTimeout(function () { document.getElementById('regularSold_' + Code).focus(); }, 100);
                    }
                }
                else if (type == "Peak") {
                    this.PeakSoldEdit[Code] = !this.PeakSoldEdit[Code];
                    if (this.PeakSoldEdit[Code]) {
                        setTimeout(function () { document.getElementById('peakSold_' + Code).focus(); }, 100);
                    }
                }
                else if (type == "LowLimit") {
                    this.LowLimitEdit[Code] = !this.LowLimitEdit[Code];
                    if (this.LowLimitEdit[Code]) {
                        setTimeout(function () { document.getElementById('LowLimit_' + Code).focus(); }, 100);
                    }
                }
                else if (type == "HighLimit") {
                    this.HighLimitEdit[Code] = !this.HighLimitEdit[Code];
                    if (this.HighLimitEdit[Code]) {
                        setTimeout(function () { document.getElementById('HighLimit_' + Code).focus(); }, 100);
                    }
                }
            },
            async savePeakEdit(type, Code) {
                let num = 0;
                let note = '';
                if (type == "Regular") {
                    num = document.getElementById('regularSold_' + Code).value;
                    note = document.getElementById('regularNote_' + Code).value;
                } else if (type == "Peak") {
                    num = document.getElementById('peakSold_' + Code).value;
                    note = document.getElementById('peakNote_' + Code).value;
                } else if(type == "LowLimit") {
                    num = document.getElementById('LowLimit_' + Code).value;
                    note = '';
                } else{
                    num = document.getElementById('HighLimit_' + Code).value;
                    note = '';
                }
                this.loading(true);
                const rs = await this.call({
                    Action: 'Product/EditPeak', Object: {
                        Code: Code,
                        Type: type,
                        Num: num,
                        Note: note
                    }
                });
                this.loading(false);                
                if (rs.Error != "") {
                    this.showToast({ isShow: true, title: '', content:rs.Error, type: 'error' });
                    this.loading(false);
                } else {
                    this.showToast({ isShow: true, title: '', content: `Save ${type} Sold of ${Code}success!`, type: 'success' });
                    for (let i = 0; i < this.data.length; i++) {
                        if (this.data[i].Code == Code) {
                            if (type == "Regular")
                                this.data[i]["RegularSold"] = num;
                            else
                                this.data[i]["PeakSold"] = num;
                            break;
                        }
                    }
                    this.togglePeakEdit(type, Code);
                    this.getAll();
                }

            },
            async getAll() {
                this.loading(true);
                if (this.dataTable != null) {
                    this.dataTable.destroy();
                }
                const rs = await this.call({ Action: 'Product/View' })
                
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    this.loading(false);
                    //no permission                    
                    if (rs.Error.includes("Permission Denied")) {
                        window.history.back();
                    }

                } else {
                    this.mapProduct = {};
                    for (let i = 0; i < rs.Data.lsProd.length; i++) {
                        this.RegularSoldEdit[rs.Data.lsProd[i].Code] = false;
                        this.mapProduct[rs.Data.lsProd[i].Code] = rs.Data.lsProd[i];
                    }
                    this.data = rs.Data.lsProd;
                    console.log(this.data);
                    this.lastUploadBy = rs.Data.lastUploadBy;
                    this.lastFile = rs.Data.lastFile;
                    this.lastUpload = rs.Data.lastUpload;   
                    setTimeout(this.doTable, 200);
                    
                }
            },
            doTable() {
                const _this = this;
                
                _this.dataTable = $('#datatable').DataTable({
                    responsive: true,
                    "lengthMenu": [[100, 150, 200, -1], [100, 150, 200, "All"]],
                    dom: 'lBfrtip',
                    buttons: [
                        {
                            extend: 'excel',
                            className: 'btn btn-primary mb-3',
                        },
                        {
                            //https://github.com/Aymkdn/html-to-pdfmake#readme
                            text: 'PDF Charts',
                            className: 'btn btn-danger mb-3',
                            action: async function (e, dt, node, config) {

                                //colects data code
                                const filterData = _this.dataTable.rows({ search: 'applied' }).data();
                                if (filterData.length <= 0) return;
                                _this.RequestChardCodes = [];

                                for (let i = 0; i < filterData.length; i++) {                                    
                                    _this.RequestChardCodes.push(filterData[i][0].replace(/<\/?[^>]+(>|$)/g, ""));
                                }

                                

                                //collect data
                                _this.loading(true);
                                if (this.dataTableHistory != null) {
                                    this.dataTableHistory.destroy();
                                }
                                const rs = await _this.call({ Action: 'Product/GetAllChartData', Object: { Codes: _this.RequestChardCodes.join(',') } });
                                
                                if (rs.Error != "") {
                                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                                    _this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                                    _this.loading(false);
                                } else {
                                    _this.dataHistoryChart = rs.Data;                                    
                                    setTimeout(_this.doProductPDFChart, 200);
                                }



                            }
                        }
                    ]
                });
                this.loading(false);
            },  

            doProductPDFChart() {
                let _this = this;
                const lsImageCharts = {};
                
                const { createCanvas, loadImage } = require('canvas')
                const canvas = createCanvas(400, 180)
                const ctxChart = canvas.getContext('2d')


                //example of drawing canvas

                //// Write "Awesome!"
                //ctx.font = '30px Impact'
                //ctx.rotate(0.1)
                //ctx.fillText('Awesome!', 50, 100)

                //// Draw line under text
                //var text = ctx.measureText('Awesome!')
                //ctx.strokeStyle = 'rgba(0,0,0,0.5)'
                //ctx.beginPath()
                //ctx.lineTo(50, 102)
                //ctx.lineTo(50 + text.width, 102)
                //ctx.stroke()

                //console.log(canvas.toDataURL());


                //=============== Last 12 months chart ==========================
                //const Labels = [];
                Chart.defaults.animation = false;
                Chart.defaults.responsive = false;
                const Titles = "Last 12 months inventory";
                const listColor = ["#198754", "#92d3ff", "#ffa2b5"];
                let dataset = {};
                dataset["label"] = 'Remain';
                dataset["backgroundColor"] = listColor[0];
                dataset["borderWidth"] = 0;
                dataset["barPercentage"] = 0.1;

                //chart options
                const chartOptions = {
                    type: 'bar',                    
                    options: {
                        maintainAspectRatio: false,
                        scales: {
                            x: {
                                display: true,
                                scaleLabel: {   // To format the scale Lebel
                                    display: true,
                                    labelString: 'X axe name',
                                    fontColor: 'black',
                                    font: {
                                        size: 3
                                    },
                                },
                                ticks: {
                                    color: "black", // To format the ticks, coming on the axis/lables which we are passing.
                                    font: {
                                        size: 8
                                    },
                                }
                            },
                            y: {
                                display: true,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Y axe name',
                                    fontColor: 'black',
                                    fontSize: 8
                                },
                                ticks: {
                                    color: "black",
                                    font: {
                                        size: 8
                                    },
                                }
                            },
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                }
                            }]
                        },
                        plugins: {
                            datalabels: {
                                anchor: 'end',
                                align: 'top',
                                formatter: Math.round,
                                font: {
                                    weight: 'bold'
                                }
                            },
                            title: {
                                display: true,
                                align: 'center',
                                text: Titles,
                                color: 'black',
                                padding: {
                                    top: 10,
                                    bottom: 10
                                },
                                font: {
                                    size: 8
                                }
                            },
                            legend: {
                                display: false,
                                labels: {
                                    // This more specific font property overrides the global property

                                    font: {
                                        size: 3
                                    },
                                    color: 'black',
                                }
                            },

                        }
                    }
                };
                for (let i = 0; i < this.RequestChardCodes.length; i++) {                    
                    dataset["data"] = this.dataHistoryChart[this.RequestChardCodes[i]];
                    const dataSets = [];
                    dataSets.push(dataset);  
                    chartOptions.data = { datasets: dataSets, };
                    chartOptions.options.plugins.title.text = this.RequestChardCodes[i] + ` - ` + this.mapProduct[this.RequestChardCodes[i]].Name;
                    //https://github.com/apertureless/vue-chartjs/issues/695                
                    let canvaschart = new Chart(ctxChart, chartOptions);
                    lsImageCharts[this.RequestChardCodes[i]]=canvaschart.toBase64Image();
                    canvaschart.destroy();
                }
                                

                //wait for chart generated.
                setTimeout(() => {
                    var htmltoPDF = ``;

                    for (let i = 0; i < this.RequestChardCodes.length; i++) {
                        //htmltoPDF += `<div style="text-align:center">` + this.RequestChardCodes[i] + ` - ` + this.mapProduct[this.RequestChardCodes[i]].Name + `</div> `;
                        htmltoPDF += `<div><img src='` + lsImageCharts[this.RequestChardCodes[i]]+ `' /> </div> `;
                    }
                    htmltoPDF += `</html>`;
                    var ret = htmlToPdfmake(htmltoPDF);                                     
                    var dd = {
                        content: ret,
                        //images: ret.images
                    }
                    pdfMake.createPdf(dd).download();

                    this.loading(false);
                }, 1000);
               
            },
            
        },
        
        created() {
            
        },
        mounted() {            
            //if already login then start, 
            //else will watch isCheckedLogin to catch its change
            //to call start function
            if (this.isCheckedLogin) this.start();
            
           
            
        },
        watch: {
            //catch status from not login to logged in 
            //to call start function.
            //Apply for reload page issue
            isCheckedLogin(newVal) {
                console.log("user index newVal", newVal);
                if (newVal) {
                    this.start();
                }
            }
        }
    };
</script>
